import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AuthUtils} from "./auth.utils";
import {ServiceCommonConstants} from "../constant/service-common.constants";
import {BehaviorSubject, catchError, of, Observable} from 'rxjs';
import {switchMap} from 'rxjs';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private AUTH_URL = ServiceCommonConstants.AUTH_URL
  private AUTH_USERNAME = ServiceCommonConstants.AUTH_USERNAME
  private _authenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public $authenticated = this._authenticated.asObservable();
  constructor(private _httpClient: HttpClient,
              private _router: Router,
  ) {
    this._authenticated.next(this.accessToken != null);
  }

  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken');
  }

  public check(): Observable<boolean> {

    let HEADER_USER = JSON.parse(localStorage.getItem('HEADER_USER'))?.userInfo;
    console.log('here is the main thing', HEADER_USER)
    if (this.accessToken && !AuthUtils.isTokenExpired(this.accessToken) && !HEADER_USER?.tempPassword) {
      return of(true);
    }
    else if(this.accessToken && HEADER_USER?.tempPassword ){
      this._router.navigate(['/login/new-pass'])
    }

    return of(false);
  }

  signInUsingToken(): Observable<any> {
    return this._httpClient.post('/auyl/auth/api/login/key', {
      accessToken: this.accessToken
    }).pipe(
      catchError(() =>
        of(false)
      ),
      switchMap((response: any) => {
        if (response.accessToken) {
          this.accessToken = response.accessToken;
        }
        return of(true);
      })
    );
  }

  signInKey(req: any): Observable<any> {
    console.log(req, 'req')
    return this._httpClient.post(`${this.AUTH_URL}`, req).pipe(
      switchMap((response: any) => {
        if (response) {
          this.accessToken = response.accessToken;
          response.username = response?.userInfo.username;
          response.fio = response?.userInfo.fio
          response.role = response?.userInfo.role
          response.roles = response?.userInfo.roles
          response.id = response?.userInfo.id
          response.tempPassword = response?.userInfo.isTempPassword
          this._authenticated.next(true);
          localStorage.setItem('HEADER_USER', JSON.stringify(response));
        }
        return of(response);
      })
    );
  }

  getByUserName(userName: string): Observable<any>{
    return this._httpClient.get(`${this.AUTH_USERNAME}/${userName}`);
  }

  getUsersByRoleAndAddress(role: string, address: string): Observable<any>{
    return this._httpClient.get(`${this.AUTH_USERNAME}?role=${role}&address=${address}`);
  }

  logout() {
    this._authenticated.next(false);
  }

}
