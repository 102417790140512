import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, Subject, takeUntil, takeWhile, tap, timer } from 'rxjs';
import {AuthService} from "../../../../core/auth/auth.service";

@Component({
  selector     : 'auth-sign-out',
  templateUrl  : './sign-out.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SignOutComponent implements OnInit, OnDestroy
{
  countdown: number = 5;
  countdownMapping: any = {
    '=1'   : '# секунд',
    'other': '# секунд'
  };
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _authService: AuthService,
    private _router: Router
  )
  {
  }
  /**
   * On init
   */
  ngOnInit(): void
  {
    this._authService.logout();

    timer(1000, 1000)
      .pipe(
        finalize(() => {
          this._router.navigate(['login']);
        }),
        takeWhile(() => this.countdown > 0),
        takeUntil(this._unsubscribeAll),
        tap(() => this.countdown--)
      )
      .subscribe();
  }

  ngOnDestroy(): void
  {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
