<div class="dropdown">
  <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="center" class="text-center">
      <!--      <div class="dropdown-heading-icon" fxLayout="row" fxLayoutAlign="center center">-->
      <!--        <mat-icon [icIcon]="icPerson"></mat-icon>-->
      <!--      </div>-->
      <div *ngIf="currentUser" class="dropdown-heading text-center">{{currentUser.fio}}</div>
    </div>

    <button [matMenuTriggerFor]="settingsMenu"
            mat-icon-button
            matTooltip="Settings"
            matTooltipPosition="before"
            type="button">
    </button>
  </div>

  <div class="dropdown-content">
    <a (click)="navigate()"
       *ngFor="let item of items; trackBy: trackById"
       [routerLink]="item.route"
       class="notification"
       fxLayout="row"
       fxLayoutAlign="start center"
       matRipple>
      <mat-icon
        [icIcon]=item.icon
        [ngClass]="item.colorClass"
        class="notification-icon"
        fxFlex="none"></mat-icon>
      <div fxFlex="auto">
        <div class="notification-label" translate="personal area"></div>
        <div class="notification-description" translate="personal information"></div>
      </div>
      <mat-icon class="notification-chevron" fxFlex="none"></mat-icon>
    </a>
  </div>

  <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="center">
    <a
      class="ml-1 text-primary-500 hover:cursor-pointer"
      (click)="close()">{{"exit" | translate}}</a>
  </div>
</div>


<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">

</mat-menu>
