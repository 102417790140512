import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CustomLayoutComponent} from './custom-layout/custom-layout.component';
import {VexRoutes} from '../@vex/interfaces/vex-route.interface';
import {QuicklinkModule, QuicklinkStrategy} from 'ngx-quicklink';
import {SignOutComponent} from "./pages/pages/auth/sign-out/sign-out.component";
import {AuthGuard} from "./core/auth/guard/auth.guard";
import {DashboardGuard} from "./core/auth/guard/dashboard.guard";

const routes: VexRoutes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/pages/auth/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  },
  {
    path: 'dashboard',
    component: CustomLayoutComponent,
    canActivate: [DashboardGuard],
    canActivateChild: [DashboardGuard],
    children: [
      {
        path: 'dashboards/analytics',
        redirectTo: '/'
      },
      {
        path: '',
        loadChildren: () => import('./pages/dashboards/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: '**',
        loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      }
    ]
  },
  {
    path: '',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/requests/requests.module').then(m => m.RequestsModule),
      }
    ]
  },
  {
    path: 'agreements',
    component: CustomLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/agreements/agreements.module').then(m => m.AgreementsModule),
      }
    ]
  },
  {
    path: 'sign-out',
    component: SignOutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/pages/auth/sign-out/sign-out.module').then(m => m.SignOutModule),
      }
    ]
  },
  {
    path: 'personal-page',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
      }
    ]
  },
  {
    path: 'reports',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
      }
    ]
  },
  {
    path: 'users',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
      }
    ]
  },
  {
    path: 'spk_management',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/spk-management/spk-management.module').then(m => m.SpkManagementModule),
      }
    ]
  },
  {
    path: 'cc-console',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/credit-committee-console/credit-committee-console.module').then(m => m.CreditCommitteeConsoleModule),
      }
    ]
  },
  {
    path: 'price-management',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/price-management/price-management.module').then(m => m.PriceManagementModule),
      }
    ]
  },
  {
    path: 'spk-info',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/spk-info/spk-info.module').then(m => m.SpkInfoModule),
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule {
}
