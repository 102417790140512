import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/services/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import icLayers from '@iconify/icons-ic/twotone-layers';
import icSettings from '@iconify/icons-ic/twotone-settings';
import { LayoutService } from '../@vex/services/layout.service';
import {ActivatedRoute, Router} from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';
import { ConfigName } from '../@vex/interfaces/config-name.model';
import {TranslateService} from "@ngx-translate/core";
import {PersistenceService} from "./core/service/persistence.service";
import {LanguageConstants} from "./core/constant/language.constants";

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'vex';
  currentAdmin: any;

  constructor(private configService: ConfigService,
              private styleService: StyleService,
              private renderer: Renderer2,
              private platform: Platform,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private localeId: string,
              private layoutService: LayoutService,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
              private splashScreenService: SplashScreenService,
              private translate: TranslateService,
              private persistence: PersistenceService,
              private router: Router
  ) {
    Settings.defaultLocale = this.localeId;
    this.currentAdmin = JSON.parse(localStorage.getItem('HEADER_USER'));

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('rtl')),
      map(queryParamMap => coerceBooleanProperty(queryParamMap.get('rtl'))),
    ).subscribe(isRtl => {
      this.configService.updateConfig({
        rtl: isRtl
      });
    });

    this.setLang();
    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('layout'))
    ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));

    this.navigationService.items = [
      // {
      //   type: 'link',
      //   label: 'Заявки',
      //   ruLabel: 'Заявки',
      //   kkLabel: 'Өтінімдер',
      //   route: '/',
      //   icon: icLayers,
      //   routerLinkActiveOptions: { exact: true },
      // },
      // {
      //   type: 'link',
      //   label: 'Договора',
      //   ruLabel: 'Договора',
      //   kkLabel: 'Шарттар',
      //   icon: icLayers,
      //   route: '/agreements'
      // },
      // {
      //   type: 'link',
      //   label: 'Продукты',
      //   ruLabel: 'Продукты',
      //   kkLabel: 'Өнімдер',
      //   icon: icLayers,
      //   route: '/**' /*TODO: fix this pls*/
      // },
      // {
      //   type: 'link',
      //   label: 'Отчеты',
      //   ruLabel: 'Отчеты',
      //   kkLabel: 'Есептер',
      //   route: '/reports',
      //   icon: icSettings
      // },
      // {
      //   type: 'link',
      //   label: 'ЗАСЕДАНИЕ РАССМОТРЕНИЯ',
      //   ruLabel: 'ЗАСЕДАНИЯ РАССМОТРЕНИЯ',
      //   kkLabel: 'ЗАСЕДАНИЯ РАССМОТРЕНИЯ',
      //   route: '/protocol',
      //   icon: icSettings
      // },
      // {
      //   type: 'link',
      //   label: 'Управление АРМ',
      //   ruLabel: 'Управление АРМ',
      //   kkLabel: 'АРМ басқару',
      //   route: '/cc-console',
      //   icon: icSettings
      // },
      // {
      //   type: 'link',
      //   label: 'Пользователи',
      //   route: '/users',
      //   icon: icSettings,
      // }
    ];
    /*TODO: fix load*/
    // if (this.currentAdmin && this.currentAdmin.role === 'ROLE_CREDIT_ADMINISTRATION_EXECUTOR') {
    //   this.navigationService.items.push({
    //     type: 'link',
    //     label: 'Пользователи',
    //     ruLabel: 'Пользователи',
    //     kkLabel: 'Есептер',
    //     route: '/users',
    //     icon: icSettings
    //   });
    // }
  }

  ngOnInit() {
    if (this.currentAdmin?.roles.includes("ROLE_MSH_ADMIN")) {
      this.router.navigate(['/dashboard']);
    }
  }

  setLang() {
    // Add languages
    this.translate.addLangs(['us', 'kz', 'ru']);

    // Set the default language
    this.translate.setDefaultLang(LanguageConstants.RU);

    // Use a language
    const currentLang = localStorage.getItem(PersistenceService.LANG);

    if (currentLang) {
      this.translate.use(currentLang);
      this.translate.currentLang = currentLang;
    } else {
      this.translate.use(LanguageConstants.RU);
      localStorage.setItem(PersistenceService.LANG, LanguageConstants.RU);
    }
    this.currentAdmin = JSON.parse(localStorage.getItem('HEADER_USER'));

  }
}
