import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import {AuthService} from "../auth.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad
{
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _router: Router
    )
    {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
    {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl);
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean
    {
        return this._check('/');
    }

    private _check(redirectURL: string): Observable<boolean>
    {
      let user = JSON.parse(localStorage.getItem('HEADER_USER'))?.userInfo;
        return this._authService.check()
                   .pipe(
                       switchMap((authenticated) => {
                         console.log(user, 'user')
                           if ( !authenticated )
                           {
                               this._router.navigate(['login']);

                               return of(false);
                           }
                           else if(authenticated && user.tempPassword){
                             this._router.navigate(['login/new-pass']);
                             return of(false);
                           }
                           return of(true);
                       })
                   );
    }
}
