<div class="flex flex-col flex-auto items-center sm:justify-center min-w-0">
  <div class="w-full sm:w-auto py-8 px-4 sm:p-12 sm:rounded-2xl sm:shadow sm:bg-card">
    <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">

      <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight text-center">Вы вышли из системы!</div>
      <div class="flex justify-center mt-0.5 font-medium">
        <ng-container *ngIf="countdown > 0">
          Перенаправление через {{countdown | i18nPlural: countdownMapping }}
        </ng-container>
        <ng-container *ngIf="countdown === 0">
          Вы сейчас перенаправлены!
        </ng-container>
      </div>
      <div class="mt-8 text-md font-medium text-secondary text-center">
        <span>Перейти</span>
        <a
          class="ml-1 text-primary-500 hover:underline"
          [routerLink]="['/login']">Войти
        </a>
      </div>
    </div>
  </div>
</div>
