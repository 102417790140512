import { Component, OnInit, ChangeDetectorRef  } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'vex-toolbar-lang',
  templateUrl: './toolbar-lang.component.html',
  styleUrls: ['./toolbar-lang.component.scss']
})
export class ToolbarLangComponent implements OnInit {

  currentLang: any = 'ru';

  constructor(private translate: TranslateService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  getLang(): string {
    return this.currentLang;
  }

  setLang(lang: string): void {
    this.currentLang = lang;
  }

  changeLang(lang: string): void {
    this.currentLang = lang;
    console.log(this.currentLang, 'this.currentLang')
    localStorage.setItem('lang', this.currentLang);
    this.translate.use(this.currentLang);
  }

}
