import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import { Icon } from '@visurel/iconify-angular';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import {UserService} from "../../../../../app/core/user/user.service";
import {MatIconModule} from "@angular/material/icon";
import {AuthService} from "../../../../../app/core/auth/auth.service";
import {Router} from "@angular/router";
import icPerson from '@iconify/icons-ic/twotone-person';
import icAccountCircle from '@iconify/icons-ic/twotone-account-circle';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: Icon;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {

  icPerson = icPerson

  items: MenuItem[] = [
    {
      id: '1',
      icon: icAccountCircle,
      label: 'Личный кабинет',
      description: 'Персональная информация',
      colorClass: 'text-teal',
      route: '/personal-page'
    }
  ];

  trackById = trackById;

  currentUser: any;

  constructor(private cd: ChangeDetectorRef,
              private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
              private router: Router,
              private userService: UserService,
              private authService: AuthService) {

    this.currentUser = this.userService.getCurrentUser().userInfo;
  }

  ngOnInit() {}

  navigate() {
    this.popoverRef.close();
  }


  close() {
    localStorage.removeItem('HEADER_USER');
    localStorage.removeItem('accessToken');
    this.authService.logout();
    this.router.navigate(['login'])
    this.popoverRef.close();

  }
}
