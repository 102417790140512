<div class="userInfo" [matMenuTriggerFor]="language">

  <button [matMenuTriggerFor]="language">
    {{ currentLang === 'kz' ? 'ҚАЗ' : 'РУС' }}
<!--    если флаг картинкасы керек болса просто раскомментить -->
<!--    <ng-container *ngTemplateOutlet="flagImage; context: {$implicit: currentLang}"></ng-container>-->
  </button>
</div>

<mat-menu #language="matMenu">
  <button mat-menu-item (click)="changeLang('kz')">
<!--    <ng-container *ngTemplateOutlet="flagImage; context: {$implicit: 'kz'}">-->
      {{ 'КАЗ' | translate }}
<!--    </ng-container>-->
  </button>
  <button mat-menu-item (click)="changeLang('ru')">
<!--    <ng-container *ngTemplateOutlet="flagImage; context: {$implicit: 'ru'}">-->
      {{ 'РУС' | translate }}
<!--    </ng-container>-->
  </button>
</mat-menu>

<ng-template let-lang #flagImage>
    <span class="relative w-6 shadow overflow-hidden">
 <img [src]="'assets/img/flags/' + lang + '.svg'" alt="Flag" class="w-full h-full">
    </span>
</ng-template>
