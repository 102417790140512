export class ServiceCommonConstants {
  public static readonly AUTH_URL = '/auyl/auth/api/login';
  public static readonly AUTH_USERNAME = '/auyl/auth'
  public static readonly LOAN_ALL = '/auyl/core/loan/all';
  public static readonly REQUEST_UPDATE = '/auyl/loan/update';
  public static readonly REQUEST_ACCEPT = '/auyl/loan/manager/accept/';
  public static readonly LOAN_ALL_STARTED = '/auyl/loan/started';
  public static readonly LOAN_ALL_STATUS = '/auyl/loan/status';
}

